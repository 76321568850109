.card {
  color: #4f4f4f;
  border: none;
}

.card-mobile {
  color: #4f4f4f;
  border: none;
  min-height: 100vh;
  border-radius: 0;
}

.card-body {
  flex: 0;
  padding: 2.5rem 1.25rem 1.25rem;
}

.logo {
  display: block;
  max-width: 65%;
  margin: auto;
  margin-bottom: .5rem;
}

.subtitle {
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
  font-family: 'Kelson';
}

.selector {
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.selector-mobile {
  flex: 1;
  border-radius: 0;
}

.selector-background {
  box-shadow: inset 0px 10px 3px rgba(0,0,0,0.45);
  -webkit-box-shadow: inset 0px 10px 3px rgba(0,0,0,0.45);
  -moz-box-shadow: inset 0px 10px 3px rgba(0,0,0,0.45);
  background-image: url('../../assets/images/sonoran-desert.jpg');
  background-position: center;
  background-size: cover;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  transform: scale(1.025);
  position: absolute;
  height: 100%;
  width: 100%;
}

.cities {
  padding: 1.5rem 2.5rem;
}

.cities-mobile {
  padding: 1.5rem;
}