.header-notrip-title {
  color: #fff;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.header-trip {
  padding-bottom: 0.5rem;
}

.header-trip-title {
  display: flex;
  align-items: center;
}

.header-trip-title>button {
  padding-left: 0;
  color: #fff;
}

.header-trip-title>button:hover {
  color: #ccc
}

.header-trip-title>div>p {
  margin-bottom: 0;
}

.header-trip-title>div>h5 {
  color: #fff;
}

.header-trip-estimates {
  display: flex;
}

.header-trip-estimates>div {
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
}

.header-trip-estimates>div>span:last-child {
  font-weight: bold;
}

.btn-create {
  width: 100%;
  font-weight: bold;
}

.disclaimer {
  color: #777;
}

.trip-list {
  padding: 0 0 1rem;
  list-style: none;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.trip-list>li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0.5rem;
}

.trip-list>li:hover {
  cursor: pointer;
}

.trip-list>li:first-child {
  margin-left: 0;
}

.trip-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: #eaeaea;
  border-radius: 5rem;
  padding: 1rem;
  line-height: 1;
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: .5rem;
}

.trip-list>li.active .trip-number,
.trip-list>li:hover .trip-number {
  background-color: #2F80ED;
  color: #fff;
}

.trip-text {
  background-color: #eaeaea;
  border-radius: .5rem;
  padding: 0 .5rem;
  font-weight: bold;
  font-size: 0.85em;
  white-space: nowrap;
}

.trip-directions {
  list-style: none;
  padding: 0;
}

.trip-directions>li {
  display: flex;
  align-items: flex-start;
}

.trip-directions>li>img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem
}

.trip-directions-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.trip-directions-text-label {
  font-size: 0.9em;
  font-weight: bold;
  color: #4f4f4f;
  line-height: 1;
}

.trip-directions-text-content {
  font-size: 1.2em;
  color: #333;
}

.trip-directions-line {
  height: 2rem;
  width: .25rem;
  margin: 0 1.45rem;
  background-color: #333;
}

.trip-map-title {
  font-weight: bold;
  color: #4f4f4f;
}

.trip-viewmap {
  margin-left: auto;
  color: #fff;
  font-weight: bold;
}

.trip-viewmap:hover {
  text-decoration: none;
  color: #fff;
}