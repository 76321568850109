.azureplaces {
  margin-bottom: 1.5rem;
}

.input-wrapper {
  display: flex;
  border-radius: .5rem;
  overflow: hidden;
}

.input-wrapper:focus {
  border-color: red;
}

.icon {
  color: #fff;
  background-color: rgb(233, 77, 25);
  padding: .75rem .5rem;
}

.icon-circle {
  border: 2px solid #fff;
  border-radius: 5rem;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle>svg {
  font-size: 1.2em;
}

.input {
  flex: 1;
}

.input>input {
  width: 100%;
  height: 100%;
  border: none;
  padding: .5rem 1rem;
  font-size: 1.2em;
}

.input>input:focus,
.input>input:active {
  outline: none;
}

.crosshair {
  background-color: #fff;
  border: none;
  color: #4f4f4f;
}

.crosshair:hover {
  color: #333;
}

.suggestions {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  margin-top: 1rem;
  border-radius: .5rem;
}

.suggestions>ul {
  list-style: none;
  margin-bottom: 0;
  padding: .5rem 0;
}

.suggestions>ul>li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #4f4f4f;
  padding: .5rem 1rem;
  line-height: 1;
}

.suggestions>ul>li:hover,
.suggestions>ul>li:focus {
  background-color: #f5f5f5;
  cursor: pointer;
}

.list-icon {
  margin-right: 1rem
}

.list-text {
  display: flex;
  flex-direction: column;
}

.poi {
  font-weight: bold;
  margin-bottom: .25rem;
}

.address {
  font-size: 0.9em;
  margin-bottom: .25rem;
}

.type {
  font-size: 0.8em;  
}