.btn-submit {
  width: 100%;
  font-weight: bold;
  margin-bottom: .75rem;
}

.terminos {
  margin-bottom: 1rem;
}

.terminos>button {
  padding: 0;
  margin-bottom: 0;
  color: #4f4f4f;
  width: 100%;
  text-align: left;
}

.terminos>button:hover {
  color: #4f4f4f;
}

.terminos>button>svg {
  margin-right: .5rem;
}