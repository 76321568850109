.list {
  margin-bottom: 0;
  list-style: none;
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.list > li {
  margin-bottom: 1rem;
}

.n-header {
  display: flex;
  justify-content: space-between;
  color: #4f4f4f;
}

.n-header > span:first-child {
  font-weight: bold;
}

.n-msg {
  margin-bottom: 0;
  color: #4f4f4f;
}

.checks {
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  color: #4f4f4f;
  display: flex;
}

.checks > button {
  color: #4f4f4f;
}

.checks > button:hover {
  text-decoration: none;
  color: #4f4f4f;
}

.checks-mobile {
  flex-direction: column;
  align-items: flex-start;
}

.checks :global .btn-link {
  text-decoration: none !important;
}
