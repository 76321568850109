.wrapper {
  background-color: #FF6A29;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .375rem .75rem 0rem;
}

.button {
  padding: 0;
  padding-bottom: .375rem;
}

.button,
.list-button {
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
  font-weight: bold;
  border-radius: .35rem
}

.button:focus,
.button:hover,
.list-button:hover {
  color: #fff;
  text-decoration: none;
}

.list-button:hover {
  filter: brightness(0.95);
}

.button img,
.list-button img {
  height: 35px;
  width: 35px;
  margin-right: .5rem;
}

.button span,
.list-button span {
  flex: 1;
  text-align: left;
}

.list {
  list-style: none;
  padding: 0;
  padding-bottom: .75rem;
  margin-top: .5rem;
  margin-bottom: 0;
}

.list li {
  margin-bottom: .5rem
}

.list li:last-child {
  margin-bottom: 0;
}
