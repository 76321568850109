.header {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.header > svg {
  margin-right: 0.5rem;
  color: #17a2b8;
  font-size: 1.2em;
}

.div {
  border-bottom: 1px solid #ccc;
}

.link {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  font-weight: bold;
}

.lines {
  padding: .5rem;
  margin-bottom: 0 !important;
}

.lines ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.time {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem 1rem;
}

.time > span:first-child {
  font-weight: bold;
  font-size: 1.25em;
  color: #4f4f4f;
}